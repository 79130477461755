import { Button, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";


export const TimeSelector = ({ sendMessage, lastJsonMessage }) => {
    const [morningLights, setMorningLights] = useState('08:00');
    const [eveningLights, setEveningLights] = useState('19:00');
    const [sleepLights, setSleepLights] = useState('23:00');
    const rpcIdRef = useRef();

    useEffect(() => {
        rpcIdRef.current = Math.random();
        sendMessage(JSON.stringify({
            jsonrpc: "2.0",
            rpcid: rpcIdRef.current,
            method: "getLedlightsConfig",
            to: "machine"
        }));
    }, []);

    useEffect(() => {
        if (lastJsonMessage && "jsonrpc" in lastJsonMessage && "result" in lastJsonMessage && rpcIdRef.current === lastJsonMessage.rpcid) {
            setMorningLights(v => lastJsonMessage.result[0]?.morning || v);
            setEveningLights(v => lastJsonMessage.result[0]?.evening || v);
            setSleepLights(v => lastJsonMessage.result[0]?.sleep || v);
        } else {
            console.log("NOT", lastJsonMessage);
        }
    }, [lastJsonMessage]);

    const sendTimes = () => {
        sendMessage(JSON.stringify({
            to: "machine",
            msg: JSON.stringify({
                ledlights: {
                    morning: morningLights,
                    evening: eveningLights,
                    sleep: sleepLights,
                }
            })
        }));
    };

    return (<>
        <TextField
            label="Morning light"
            type="time"
            value={morningLights}
            onChange={e => setMorningLights(e.target.value)}
            style={{
                marginTop: "16px",
            }}
        />
        <TextField
            label="Evening light"
            type="time"
            value={eveningLights}
            onChange={e => setEveningLights(e.target.value)}
            style={{
                marginTop: "16px",
            }}
        />
        <TextField
            label="Sleep time"
            type="time"
            value={sleepLights}
            onChange={e => setSleepLights(e.target.value)}
            style={{
                marginTop: "16px",
            }}
        />

        <Button
            style={{
                marginTop: "8px",
            }}
            onClick={sendTimes}
        >Send</Button>
    </>);
}
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ChromePicker } from "react-color";
import { ReadyState } from "react-use-websocket";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import { CommandForm } from "./CommandForm";
import { TimeSelector } from "./TimeSelector";
import { useRpc } from "./jsonRpc";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function hueToRgb(p, q, t) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}
function hslToRgb(h, s, l) {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hueToRgb(p, q, h + 1 / 3);
    g = hueToRgb(p, q, h);
    b = hueToRgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

function debounce(func, timeout = 300) {
  let timer;
  let f;
  let a;
  return (...args) => {
    if (!timer) {
      timer = setTimeout(() => {
        f.apply(this, a);
        timer = null;
      }, timeout);
    }
    f = func;
    a = args;
  };
}

const useDebounce = (callback) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, 250);
  }, []);

  return debouncedCallback;
};

export function Page({ token }) {
  const { sendMessage, readyState, lastJsonMessage, getAccessTokenSilently } = useWebSocket(
    //"ws://localhost:8080/ws",
    "wss://ledlights.mashaogthomas.no/ws",
    {
      shouldReconnect: () => true,
      reconnectAttempts: 99999,
      reconnectInterval: 1000,
      onError: console.error,
    }
  );
  const { callRpc } = useRpc({ sendMessage, lastJsonMessage });
  const [color, setColor] = useState(JSON.parse(window.localStorage.getItem("color")) || { r: 0, g: 0, b: 0, a: 0 });
  const [selectedClient, setSelectedClient] = useState(window.localStorage.getItem("selectedClient") || "");
  const [clients, setClients] = useState([]);
  const [meta, setMeta] = useState({});
  const [brightness, setBrightness] = useState(Number(window.localStorage.getItem("brightness") || "64"));

  useEffect(() => {
    window.localStorage.setItem("color", JSON.stringify(color));
    window.localStorage.setItem("selectedClient", (selectedClient));
    window.localStorage.setItem("brightness", (brightness));
  }, [
    color,
    selectedClient,
    brightness
  ]);

  useEffect(() => {
    if (readyState !== ReadyState.OPEN) {
      return;
    }
    sendMessage("l");
    const id = setInterval(() => {
      sendMessage("l");
    }, 5000);
    return () => {
      console.log("stopped");
      clearInterval(id);
    };
  }, [readyState]);

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }
    if (lastJsonMessage === "ping") {
      sendMessage("pong");
      return;
    }
    if (typeof lastJsonMessage === "object" && "clients" in lastJsonMessage) {
      setClients(lastJsonMessage.clients);
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    if (!clients || clients.length === 0) {
      return;
    }
    console.log(clients);
    Promise.all(
      clients
        .filter((c) => !(c in meta))
        .map(async (c) =>
          fetch("https://iot.mashaogthomas.no/api/client?id=" + btoa(c), {
            headers: {
              Authorization: "Bearer " + getCookie("X-TOKEN"),
            }
          }).then((r) =>
            r.json()
          )
        )
    )
      .then((p) => p.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), meta))
      .then((o) => setMeta(o));
  }, [clients, getAccessTokenSilently]);

  const clientsWithMeta = useMemo(() => clients.map((id) => meta[id]).filter(Boolean).map(c => ({ group: 'client', ...c })), [clients, meta]);

  const programs = useMemo(() => Array.from(new Set(clientsWithMeta.map((c) => c.program).filter(Boolean))).map(program => ({ id: program, group: 'program' })), [clientsWithMeta]);
  const rooms = useMemo(() => Array.from(new Set(clientsWithMeta.map((c) => c.room).filter(Boolean))).map(room => ({ id: room, group: 'group' })), [clientsWithMeta]);
  const options = useMemo(() => [
    ...rooms,
    ...clientsWithMeta,
    ...programs,
  ], [clientsWithMeta, rooms]);

  const sendUpdate = useCallback((msg) => {
    const sendToId = (id) => {
      sendMessage(
        JSON.stringify({
          id,
          msg,
        })
      );
    };
    const sendToRoom = (room) => {
      sendMessage(
        JSON.stringify({
          room,
          msg,
        })
      );
    };
    const sendToProgram = (program) => {
      sendMessage(
        JSON.stringify({
          program,
          msg,
        })
      );
    };
    if (selectedClient === "") {
      return;
    }

    if (rooms.some(room => room.id === selectedClient)) {
      sendToRoom(selectedClient);
      return;
    }
    if (programs.some(program => program.id === selectedClient)) {
      sendToProgram(selectedClient);
      return;
    }
    sendToId(selectedClient === "all" ? undefined : selectedClient);
  }, [sendMessage, selectedClient, rooms]);

  const updateColor = useDebounce(() => {
    sendUpdate(
      "c " + color.r + " " + color.g + " " + color.b + " " + Math.round(color.a * 255)
    );
  });


  useEffect(() => {
    if (selectedClient === "") {
      return;
    }

    const selector = {};
    if (rooms.some(room => room.id === selectedClient)) {
      selector.room = selectedClient;
    } else if (programs.some(program => program.id === selectedClient)) {
      selector.program = selectedClient;
    } else {
      selector.id = selectedClient;
    }

    callRpc(selector, "getConfig")
      .then((result) => {
        if (!result || !result[0]) {
          return;
        }
        setBrightness(result[0].brightness);
        setColor({
          r: result[0].r,
          g: result[0].g,
          b: result[0].b,
          a: result[0].a,
        });
      });
  }, [selectedClient]);

  if (readyState !== ReadyState.OPEN) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            columnWidth: 2,
          }}
        >
          <Autocomplete
            value={options.find(o => o.id === selectedClient)}
            onChange={(_, v) => setSelectedClient(v.id)}
            groupBy={(option) => option.group}
            getOptionLabel={(option) => option.id}
            options={options}
            renderInput={(params) => <TextField {...params} label="Clients" />}
          />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            columnWidth: 2,
          }}
        >
          <Typography variant="h5">Mode</Typography>
          <Box>
            <Button
              onClick={() => sendUpdate("m " + 0)}
              variant="contained"
              sx={{
                m: 1,
              }}
            >
              OFF
            </Button>
            <Button
              onClick={() => sendUpdate("m " + 1)}
              variant="contained"
              sx={{
                m: 1,
              }}
            >
              ON
            </Button>
            {new Array(6).fill(0).map((_, n) => (
              <Button
                key={n}
                onClick={() => sendUpdate("m " + (n + 2))}
                variant="contained"
                sx={{
                  m: 1,
                }}
              >
                {n + 2}
              </Button>
            ))}
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            columnWidth: 2,
          }}
        >
          <Typography variant="h5">Color</Typography>
          <ChromePicker
            styles={{
              default: {
                picker: {
                  width: 'auto',
                  maxWidth: '500px'
                }
              }
            }}
            color={color}
            onChange={(c) => {
              updateColor();
              setColor(c.rgb);
            }}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            columnWidth: 2,
          }}
        >
          <Typography variant="h5">Brightness</Typography>
          <Slider
            min={0}
            max={255}
            defaultValue={64}
            value={brightness}
            onChange={(_, v) => { sendUpdate("b " + v); setBrightness(v); }}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            columnWidth: 2,
          }}
        >
          <Typography variant="h5">Send Command</Typography>
          <CommandForm sendMessage={sendMessage} sendUpdate={sendUpdate} />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            columnWidth: 2,
          }}
        >
          <Typography variant="h5">Lights</Typography>
          <TimeSelector sendMessage={sendMessage} lastJsonMessage={lastJsonMessage} />
        </Paper>
      </Grid>
    </Grid>
  );
}

import "./App.css";
import LoginButton from "./LoginButton";
import { useAuth0 } from "@auth0/auth0-react";
import { Page } from "./Page";
import { useEffect, useState } from "react";

function setCookie(name, value, ms) {
  var expires = "";
  if (ms) {
    var date = new Date();
    date.setTime(date.getTime() + (ms));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function App() {
  const { error, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState(null);

  console.log(error);

  useEffect(() => {
    const updateCookie = () => {
      getAccessTokenSilently().then(token => {
        setCookie("X-TOKEN", token, 60 * 60 * 1000);
        setToken(token);
      });
    }
    const interval = setInterval(() => {
      updateCookie();
    }, 30 * 60 * 1000);
    updateCookie();

    return () => clearInterval(interval);
  }, [
    isAuthenticated
  ])

  if (isAuthenticated && token) {
    return <Page token={token} />;
  }

  return (
    <div className="App">
      <header className="App-header">
        <LoginButton />
      </header>
    </div>
  );
}

export default App;

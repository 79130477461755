import { useEffect } from "react";


const rpcCalls = {};

export function useRpc({ sendMessage, lastJsonMessage }) {

    const callRpc = async (to, method, params) => {
        const rpcid = Math.random();
        sendMessage(JSON.stringify({
            jsonrpc: "2.0",
            rpcid,
            ...to,
            method,
            params,
        }));
        const timeout = setTimeout(() => {
            if (rpcid in rpcCalls) {
                rpcCalls[rpcid].rej("timeout");
                delete rpcCalls[rpcid];
            }
        }, 5000);
        const response = await new Promise((res, rej) => {
            rpcCalls[rpcid] = {
                res, rej
            }
        });
        clearTimeout(timeout);
        return response;
    }

    useEffect(() => {
        if (!lastJsonMessage) {
            return;
        }
        if (!lastJsonMessage.rpcid in rpcCalls) {
            console.warn("rpcid not in rpcCalls", lastJsonMessage.rpcid, Object.keys(rpcCalls))
            return;
        }
        if (lastJsonMessage.result) {
            rpcCalls[lastJsonMessage.rpcid]?.res(lastJsonMessage.result);
            return;
        }
        if (lastJsonMessage.error) {
            rpcCalls[lastJsonMessage.rpcid]?.rej(lastJsonMessage.error);
            return;
        }
    }, [lastJsonMessage]);

    return {
        callRpc,
    }
}
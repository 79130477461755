import { Button, TextField } from "@mui/material";
import { useState } from "react";

export function CommandForm({ sendMessage, sendUpdate }) {
  const [value, setValue] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    sendUpdate(value);
    setValue("");
  };
  const onSubmitRaw = (e) => {
    e.preventDefault();
    sendMessage(value);
    setValue("");
  };

  return (
    <form onSubmit={onSubmit}>
      <TextField value={value} onChange={(v) => setValue(v.target.value)} />
      <Button type="submit">Send to clients</Button>
      <Button onClick={onSubmitRaw}>Send raw</Button>
    </form>
  );
}
